<template>
  <div v-show="props.compType === 'singleLineText'">
    <el-form-item label="标题">
        <el-input class="input" v-model="props.label"></el-input>
    </el-form-item>
    <!-- <el-form-item label="文字内容">
        <el-input class="input" v-model="props.value"></el-input>
    </el-form-item> -->
    <!-- <el-form-item label="对齐方式">
        <el-radio-group v-model="props.textAlign">
            <el-radio-button label="left">居左</el-radio-button>
            <el-radio-button label="center">居中</el-radio-button>
            <el-radio-button label="right">居右</el-radio-button>
        </el-radio-group>
    </el-form-item> -->
    <el-form-item label="最多输入字符">
        <el-input v-model="props.maxLength"></el-input>
    </el-form-item>
    <el-form-item label="栅格">
      <el-input-number v-model="props.span"  :min="1" :max="24"/>
    </el-form-item>
    <!-- <el-form-item label="栅格间隔">
      <el-input-number v-model="props.gutter"  :min="0"></el-input-number>
    </el-form-item> -->
    <el-form-item label="标签宽度">
      <el-input-number v-model="props.labelWidth"  :min="1" :max="200"></el-input-number>
    </el-form-item>
    <el-form-item label="必填">
      <el-switch v-model="props.required"></el-switch>
    </el-form-item>
  </div>
</template>
<script>
/**
 * input的配置项
 */
export default {
  name:"inputConfig",
  props:['props','getFormId'],
  data(){
    return {
      val:'',
    }
  },
  methods:{
  },
  mounted(){
  },
  watch:{
  }
}
</script>
<style lang="scss" scoped>
    .input{
        width:75%
    }
    .rule-item{
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .close-btn{
        text-align: center;
    }
    .close-icon:hover{
        cursor: pointer;
    }
    .close-btn >>> .el-icon-remove-outline{
        color:"red"
    }
    .el-form-item /deep/ .el-input__inner {
        height: 32px;
    }
</style>